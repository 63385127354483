import React, { ReactNode } from "react";
import { fontFamily } from "../hooks/font";
import { colors } from "../hooks/color";

interface RoundButtonProps {
  children?: ReactNode;
  color?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  handleClick?: () => void;
  disabled?: boolean;
  outlined?: boolean;
  fullWidth?: boolean;
}

function RoundButton({
  children,
  color = colors.ivory,
  backgroundColor = colors.black,
  style,
  handleClick,
  disabled,
  outlined,
  fullWidth,
}: RoundButtonProps) {
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontFamily: fontFamily.manrope,
        fontSize: 16,
        borderRadius: 50,
        padding: "5px 20px",
        backgroundColor: outlined ? "inherit" : backgroundColor,
        color,
        fontWeight: 600,
        textTransform: "initial",
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "auto",
        cursor: disabled ? "default" : "pointer",
        border: outlined ? `1px solid ${backgroundColor}` : undefined,
        width: fullWidth ? "100%" : undefined,
        ...style,
      }}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}

export default RoundButton;
