import React, { ReactNode, useEffect, useState } from "react";
import CustomDialog from "./CustomDialog";
import { fontFamily } from "../hooks/font";
import { colors } from "../hooks/color";
import { fetchData } from "../api";
import { useImageUpload } from "../hooks/image";
import { useUser, useUserHooks } from "../hooks/user";
import CustomImage from "./CustomImage";
import ImageUpload from "../inputs/ImageUpload";
import RoundButton from "./RoundButton";
import { getEmail } from "../util";
import { UserVerificationStatus } from "../enums";
import { ReactComponent as CheckSvg } from "../svgs/check.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { SigninComponent } from "../pages/Sign";

interface VerificationDialog {
  open: boolean;
  handleClose: () => void;
  callback?: () => void;
}

function IdVerificationDialog({ open, handleClose }: VerificationDialog) {
  const user = useUser();
  const [imageSrc, setImageSrc] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { imageUpload } = useImageUpload();
  const { patchUserIdentity } = useUserHooks();

  useEffect(() => {
    if (!user) return;
    setImageSrc(user.meta.identity_image || "");
  }, [user]);

  useEffect(() => {
    if (!file) {
      setImageSrc("");
    } else {
      setImageSrc(URL.createObjectURL(file));
    }
  }, [file]);

  return (
    <CustomDialog open={open} handleClose={handleClose} fullWidth>
      {!submitted && (
        <>
          <div
            style={{
              color: colors.orange,
              fontSize: 24,
              fontFamily: fontFamily.simula,
            }}
          >
            Verify your identity
          </div>
          <div style={{ marginTop: 30 }}>
            {imageSrc && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CustomImage
                    style={{
                      borderRadius: 7,
                      width: 115,
                      height: 60,
                    }}
                    src={imageSrc}
                  />
                  <CheckSvg />
                </div>
                <div
                  style={{
                    marginTop: 30,
                  }}
                >
                  <ImageUpload
                    handleChange={setFile}
                    style={{ display: "inline-flex" }}
                  >
                    <span
                      style={{ textDecoration: "underline", fontWeight: 600 }}
                    >
                      Replace
                    </span>
                  </ImageUpload>
                </div>
              </>
            )}
            {!imageSrc && (
              <>
                <div>
                  Please upload a photo of your government issued ID to verify
                  your identity. This is to ensure safety and security for our
                  users.
                </div>
                <div style={{ marginTop: 30 }}>
                  <ImageUpload
                    handleChange={setFile}
                    style={{ display: "inline-flex" }}
                  >
                    <span
                      style={{ textDecoration: "underline", fontWeight: 600 }}
                    >
                      Upload Photo
                    </span>
                  </ImageUpload>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              marginTop: 30,
              textAlign: "center",
            }}
          >
            <RoundButton
              disabled={!Boolean(file)}
              handleClick={() => {
                if (!file) return;
                imageUpload(file)
                  .then(({ location }) => patchUserIdentity(location))
                  .then(() => setSubmitted(true));
              }}
              style={{ width: 100 }}
            >
              Verify
            </RoundButton>
          </div>
        </>
      )}
      {submitted && (
        <>
          <div
            style={{
              color: colors.orange,
              fontSize: 24,
              fontFamily: fontFamily.simula,
            }}
          >
            Thank you!
          </div>
          <div style={{ marginTop: 30 }}>
            Email will be sent once we confirm your identity.
            <br />
            Once confirmed, you will be able to book an event.
            <br />
            <br />
            Although we try to make the process as speedy as possible, the
            verification process can take up to five business days.
            <br />
            <br />
            Thank you for your patience and please do not hesitate to email us
            at {getEmail()} for any questions.
          </div>
        </>
      )}
    </CustomDialog>
  );
}

function EmailVerificationDialog({ open, handleClose }: VerificationDialog) {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <div
        style={{
          fontSize: 25,
          fontFamily: fontFamily.simula,
          color: colors.orange,
        }}
      >
        Please check your inbox and confirm your email
      </div>
      <div style={{ marginTop: 30, fontSize: 14 }}>
        Please click button below <br />
        to receive email again
      </div>
      <div
        style={{
          display: "inline-block",
          marginTop: 30,
          fontSize: 14,
          textDecoration: "underline",
          color: colors.orange,
          cursor: "pointer",
        }}
        onClick={() => fetchData("POST", `/users/confirmation/resend`)}
      >
        Resend Email
      </div>
    </CustomDialog>
  );
}

function IdPendingDialog({ open, handleClose }: VerificationDialog) {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <div
        style={{
          fontSize: 25,
          fontFamily: fontFamily.simula,
          color: colors.orange,
        }}
      >
        We’re verifying your identity at the moment
      </div>
      <div style={{ marginTop: 30, fontSize: 14 }}>
        You will receive a confirmation email once verified.
      </div>
    </CustomDialog>
  );
}

function SigninDialog({ open, handleClose, callback }: VerificationDialog) {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 700 }}>
        Please sign in to continue your activity
      </div>
      <div style={{ marginTop: 30, display: "flex", justifyContent: "center" }}>
        <SigninComponent callback={callback} />
      </div>
    </CustomDialog>
  );
}

interface VerificationWrapperProps {
  children?: ReactNode;
  handleClick?: () => void;
}

function VerificationWrapper({
  children,
  handleClick,
}: VerificationWrapperProps) {
  const [openSignin, setOpenSignin] = useState<boolean>(false);
  const user = useUser();

  useEffect(() => {
    if (user) {
      setOpenSignin(false);
    }
  }, [user]);

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          cursor: "pointer",
        }}
        onClick={() => {
          // check validation rule first
          if (!user) {
            return setOpenSignin(true);
          }
          if (handleClick) {
            handleClick();
          }
        }}
      >
        {children}
      </div>
      <SigninDialog
        open={openSignin}
        handleClose={() => setOpenSignin(false)}
        callback={handleClick}
      />
    </>
  );
}

export default VerificationWrapper;
