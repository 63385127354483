import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomImage from "../components/CustomImage";
import LogoIcon from "../pngs/logo.png";
import { SigninComponent } from "./Sign";
import { useUser, useUserHooks } from "../hooks/user";
import ImageUpload from "../inputs/ImageUpload";
import { useImageUpload } from "../hooks/image";
import { GlobalContext } from "../App";
import Avatar from "../components/Avatar";
import { colors } from "../hooks/color";
import { ReactComponent as InstagramSvg } from "../svgs/instagram.svg";
import RoundButton from "../components/RoundButton";
import Horizontal from "../components/Horizontal";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Header from "../components/Header";
import InfoComponent from "../components/InfoComponent";
import TextInput from "../inputs/TextInput";
import { UploadPhotoImage } from "../components/UploadPhotos";
import TimeUtil from "../utils/time";
import SelectInput from "../inputs/SelectInput";
import { allergens, USStates } from "../const";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ScrollSelect from "../inputs/ScrollSelect";
import CustomDialog from "../components/CustomDialog";
import { ReactComponent as AddSvg } from "../svgs/add.svg";
import { v4 } from "uuid";
import { fetchData } from "../api";
import CustomSwiper from "../components/CustomSwiper";
import { SwiperClass } from "swiper/react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Backward from "../components/Backward";

interface EventCardProps {
  event: ProfileEvent;
}

function EventCard({ event }: EventCardProps) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/events/${event.event_code}`)}
    >
      <CustomImage
        width={250}
        height={170}
        style={{
          borderRadius: 20,
        }}
        src={event.image}
      />
      <div
        style={{
          position: "absolute",
          top: 15,
          left: 15,
        }}
      >
        <RoundButton style={{ fontSize: 12, fontWeight: 700 }}>
          {event.hosting ? "Hosting" : "Attending"}
        </RoundButton>
      </div>
      <div
        style={{
          marginTop: 10,
          fontWeight: 700,
          fontSize: 16,
        }}
      >
        {event.title}
      </div>
      <div
        style={{
          marginTop: 5,
          fontWeight: 600,
          fontSize: 12,
          color: "#838383",
          display: "flex",
          columnGap: 5,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        Hosted by <Avatar src={event.host_image} width={20} height={20} />{" "}
        {event.host_name}
      </div>
    </div>
  );
}

interface EventMenu {
  id: string;
  title: string;
  description: string;
  allergens: string[];
}

interface Event {
  id: number;
  created_at: string;
  meta: EventMeta;
}

interface EventMeta {
  event_code: string;
  images: string[];
  title: string;
  description: string;
  entry_instruction: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  date: string;
  start_time: string;
  end_time: string;
  capacity: number;
  price: number;
  home_rule: string;
  menus: EventMenu[];
  venmo: string;
}

interface EntryPageProps {
  handleBack: () => void;
}

interface MenuCardComponentProps {
  menu: EventMenu;
  index: number;
  menus: EventMenu[];
  setMenus: (menus: EventMenu[]) => void;
}

function MenuCardComponent({
  menu,
  index,
  menus,
  setMenus,
}: MenuCardComponentProps) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      {index > 0 && (
        <Horizontal
          marginTop={15}
          marginBottom={15}
          horizontalColor={colors.black}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 32,
            height: 32,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: colors.ivory,
            color: colors.black,
            borderRadius: "50%",
          }}
        >
          {index + 1}
        </div>
        {menus.length > 1 && (
          <div
            style={{
              width: 32,
              height: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: colors.ivory,
              borderRadius: "50%",
            }}
            onClick={() => {
              menus.splice(index, 1);
              setMenus([...menus]);
            }}
          >
            <DeleteIcon
              style={{
                color: colors.black,
              }}
            />
          </div>
        )}
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          dark
          label="Menu Title"
          handleChange={(title) => {
            if (title.length > 50) return;
            menu.title = title;
            setMenus([...menus]);
          }}
          value={menu.title}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          dark
          label="Description"
          handleChange={(description) => {
            if (description.length > 100) return;
            menu.description = description;
            setMenus([...menus]);
          }}
          multiline
          rows={3}
          value={menu.description}
        />
      </div>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: colors.black,
          }}
        >
          Allergens
        </div>
        <AddSvg style={{ cursor: "pointer" }} onClick={() => setOpen(true)} />
      </div>
      {menu.allergens.length > 0 && (
        <div style={{ marginTop: 15, color: colors.black }}>
          {menu.allergens.join(", ")}
        </div>
      )}
      <CustomDialog open={open} handleClose={() => setOpen(false)} fullWidth>
        <ScrollSelect
          items={allergens.map((allergen) => ({
            label: allergen,
            value: allergen,
          }))}
          handleChange={(values) => {
            menu.allergens = values;
            setMenus([...menus]);
          }}
          label="Allergens"
          values={menu.allergens}
        />
      </CustomDialog>
    </>
  );
}

interface EntryPageProps {
  handleBack: () => void;
}

interface EventEditProps {
  handleBack?: () => void;
}

export function EventEdit({ handleBack }: EventEditProps) {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [images, setImages] = useState<UploadPhotoImage[]>([]);
  const [entryInstruction, setEntryInstruction] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [capacity, setCapacity] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [venmo, setVenmo] = useState<string>("");
  const [menus, setMenus] = useState<EventMenu[]>([
    {
      id: v4(),
      description: "",
      title: "",
      allergens: [],
    },
  ]);
  const [homeRule, setHomeRule] = useState<string>("");
  const { imageUpload } = useImageUpload();
  const { toggleSpinner } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { eventCode } = useParams();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [current, setCurrent] = useState<number>(0);
  const [fileInputRef, setFileInputRef] = useState<HTMLInputElement | null>(
    null
  );
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  if (eventCode) {
    handleBack = () => navigate(`/events/${eventCode}`);
  }

  useEffect(() => {
    if (!swiper) return;
    swiper.on("activeIndexChange", (swiper: SwiperClass) =>
      setCurrent(swiper.activeIndex)
    );
  }, [swiper]);

  useEffect(() => {
    if (!eventCode) return;
    fetchData<Event>("GET", `/events/${eventCode}`).then((event) => {
      const {
        images,
        title,
        description,
        entry_instruction,
        address1,
        address2,
        city,
        state,
        zipcode,
        date,
        start_time,
        end_time,
        capacity,
        price,
        home_rule,
        menus,
        venmo,
      } = event.meta;
      setTitle(title);
      setDescription(description);
      setImages(images.map((image) => ({ file: null, location: image })));
      setEntryInstruction(entry_instruction);
      setAddress1(address1);
      setAddress2(address2);
      setCity(city);
      setState(state);
      setZipcode(zipcode);
      setDate(date);
      setStartTime(start_time);
      setEndTime(end_time);
      setCapacity(capacity);
      setPrice(price);
      setVenmo(venmo);
      setHomeRule(home_rule);
      setMenus(menus);
    });
  }, [eventCode]);

  const handleSave = async () => {
    toggleSpinner();
    // do image first
    const event_images = await Promise.all(
      images.map((image) => {
        if (!image.file) {
          return image.location;
        }
        return imageUpload(image.file).then((image) => image.location);
      })
    );
    if (!eventCode) {
      fetchData<Event>("POST", "/events", {
        title,
        description,
        images: event_images,
        entry_instruction: entryInstruction,
        address1,
        address2,
        city,
        state,
        zipcode,
        date,
        start_time: startTime,
        end_time: endTime,
        capacity,
        price,
        venmo,
        home_rule: homeRule,
        menus,
      })
        .then((hostEvent) => {
          toggleSpinner();
          navigate(`/events/${hostEvent.meta.event_code}`);
        })
        .catch(toggleSpinner);
    } else {
      fetchData<Event>("PUT", `/events/${eventCode}`, {
        title,
        description,
        images: event_images,
        entry_instruction: entryInstruction,
        address1,
        address2,
        city,
        state,
        zipcode,
        date,
        start_time: startTime,
        end_time: endTime,
        capacity,
        price,
        venmo,
        home_rule: homeRule,
        menus,
      })
        .then((hostEvent) => {
          toggleSpinner();
          navigate(`/events/${hostEvent.meta.event_code}`);
        })
        .catch(toggleSpinner);
    }
  };

  let disabledCondition = false;
  if (
    !(
      Boolean(images.length > 0) &&
      Boolean(title) &&
      Boolean(description) &&
      Boolean(address1) &&
      Boolean(city) &&
      Boolean(state) &&
      Boolean(zipcode) &&
      Boolean(date) &&
      Boolean(startTime) &&
      Boolean(endTime) &&
      Boolean(capacity) &&
      Boolean(menus.length > 0)
    )
  ) {
    disabledCondition = true;
  }
  if (price > 0 && !Boolean(venmo)) {
    disabledCondition = true;
  }
  menus.forEach((menu) => {
    if (!(Boolean(menu.title) && Boolean(menu.description))) {
      disabledCondition = true;
    }
  });

  return (
    <>
      <Backward type="inverted" handleClick={handleBack} />
      <div
        style={{
          position: "relative",
        }}
      >
        {images.length === 0 && <CustomImage height={250} />}
        {images.length > 0 && (
          <CustomSwiper
            slides={images.map((image, index) => (
              <CustomImage height={250} src={image.location} key={index} />
            ))}
            setSwiper={setSwiper}
          />
        )}
        {images.length > 0 && (
          <div
            style={{ zIndex: 1, position: "absolute", bottom: 30, right: 100 }}
          >
            <RoundButton
              style={{
                opacity: 0.8,
              }}
            >
              {current + 1}/{images.length}
            </RoundButton>
          </div>
        )}
        <div style={{ zIndex: 1, position: "absolute", bottom: 30, left: 30 }}>
          <div
            style={{
              width: 50,
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: colors.ivory,
              borderRadius: "50%",
            }}
            onClick={() => {
              images.splice(current, 1);
              setImages([...images]);
              if (current === images.length) {
                setCurrent(current - 1);
              }
            }}
          >
            <DeleteIcon
              style={{
                color: colors.black,
              }}
            />
          </div>
        </div>
        <div style={{ zIndex: 1, position: "absolute", bottom: 30, right: 30 }}>
          <div
            style={{
              width: 50,
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: colors.ivory,
              borderRadius: "50%",
            }}
            onClick={() => {
              if (!fileInputRef) return;
              fileInputRef.click();
            }}
          >
            <AddIcon
              style={{
                color: colors.black,
              }}
            />
            <input
              type="file"
              name="file"
              accept="image/*"
              multiple
              onChange={(event) => {
                const files = event.target.files;
                if (!files || files.length === 0) return;
                setImages([
                  ...images,
                  ...Array.from(files).map((file) => ({
                    file,
                    location: URL.createObjectURL(file),
                  })),
                ]);
                event.target.value = "";
              }}
              ref={setFileInputRef}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 30,
          display: "flex",
          flexDirection: "column",
          rowGap: 15,
        }}
      >
        <div>
          <TextInput
            label="Event Title"
            handleChange={setTitle}
            value={title}
          />
        </div>
        <div>
          <TextInput
            type="number"
            label="Number of Seats"
            handleChange={(value) => setCapacity(Number(value))}
            value={String(capacity)}
            inputProps={{
              min: 0,
            }}
          />
        </div>
        <div>
          <TextInput
            type="number"
            label="Price Per Person ($)"
            handleChange={(value) => setPrice(Number(value))}
            value={String(price)}
            inputProps={{
              min: 0,
            }}
          />
        </div>
        {price > 0 && (
          <div>
            <TextInput
              label="Venmo ID (Exclude @)"
              handleChange={setVenmo}
              value={venmo}
            />
          </div>
        )}
        <div>
          <TextInput
            label="Date and Time"
            value={
              date && startTime && endTime
                ? TimeUtil.formatDateStartEndTime(date, startTime, endTime)
                : ""
            }
            handleClick={() => setOpenDate(true)}
          />
        </div>
        <div>
          <TextInput
            label="Location"
            value={[address1, address2, city, state, zipcode]
              .filter(Boolean)
              .join(", ")}
            multiline
            handleClick={() => setOpenLocation(true)}
          />
        </div>
        <div>
          <TextInput
            label="Description"
            handleChange={setDescription}
            multiline
            rows={5}
            value={description}
          />
        </div>
        <div
          style={{
            borderRadius: 20,
            backgroundColor: colors.blue,
            padding: 30,
            marginLeft: -30,
            marginRight: -30,
            marginTop: 30,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                width: 120,
                height: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: colors.ivory,
                color: colors.black,
                borderRadius: 32,
                fontSize: 14,
                columnGap: 5,
              }}
              onClick={() => {
                setMenus([
                  ...menus,
                  {
                    id: v4(),
                    title: "",
                    description: "",
                    allergens: [],
                  },
                ]);
              }}
            >
              <AddIcon />
              <div>Add Menu</div>
            </div>
          </div>
          <DragDropContext
            onDragEnd={(props) => {
              if (!props.destination) return;
              // swap index
              const temp = menus[props.source.index];
              menus[props.source.index] = menus[props.destination.index];
              menus[props.destination.index] = temp;
              setMenus([...menus]);
            }}
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {menus.map((menu, index) => (
                    <Draggable
                      draggableId={menu.id}
                      index={index}
                      key={menu.id}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginTop: 20,
                          }}
                        >
                          <MenuCardComponent
                            menu={menu}
                            index={index}
                            menus={menus}
                            setMenus={setMenus}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div>
          <TextInput
            label="House Rules"
            handleChange={setHomeRule}
            multiline
            value={homeRule}
            sublabel="(Optional)"
          />
        </div>
        <div>
          <TextInput
            label="Entry Instruction"
            handleChange={setEntryInstruction}
            multiline
            value={entryInstruction}
            sublabel="(Optional)"
          />
        </div>
        <div style={{ marginTop: 15, textAlign: "center" }}>
          <RoundButton
            handleClick={handleSave}
            backgroundColor={colors.yellow}
            color={colors.black}
            disabled={disabledCondition}
          >
            {eventCode ? "Save Event" : "Publish Event"}
          </RoundButton>
        </div>
      </div>
      <CustomDialog
        open={openDate}
        handleClose={() => setOpenDate(false)}
        fullWidth
        dark
      >
        <div style={{ display: "flex", flexDirection: "column", rowGap: 15 }}>
          <div>
            {(() => {
              const minDateString = TimeUtil.getNowDateString();
              return (
                <TextInput
                  fullWidth
                  type="date"
                  label="Date"
                  handleChange={(date) => {
                    if (date < minDateString) return;
                    setDate(date);
                  }}
                  value={date}
                  inputProps={{
                    min: minDateString,
                  }}
                />
              );
            })()}
          </div>
          <div>
            <TextInput
              fullWidth
              type="time"
              label="Start Time"
              handleChange={setStartTime}
              value={startTime}
            />
          </div>
          <div>
            <TextInput
              fullWidth
              type="time"
              label="End Time"
              handleChange={setEndTime}
              value={endTime}
            />
          </div>
        </div>
      </CustomDialog>
      <CustomDialog
        open={openLocation}
        handleClose={() => setOpenLocation(false)}
        fullWidth
        dark
      >
        <div style={{ display: "flex", flexDirection: "column", rowGap: 15 }}>
          <div>
            <TextInput
              name="address1"
              label="Street Address"
              handleChange={setAddress1}
              value={address1}
            />
          </div>
          <div>
            <TextInput
              name="address2"
              label="Apt. / Suite Number (Optional)"
              handleChange={setAddress2}
              value={address2}
            />
          </div>
          <div>
            <TextInput
              name="city"
              label="City"
              handleChange={setCity}
              value={city}
            />
          </div>
          <div>
            <SelectInput
              name="state"
              label="State"
              value={state}
              items={USStates.map((state) => ({
                value: state,
                label: state,
              }))}
              handleChange={setState}
            />
          </div>
          <div>
            <TextInput
              name="zipcode"
              label="Zip Code"
              handleChange={setZipcode}
              maxLength={5}
              value={zipcode}
            />
          </div>
        </div>
      </CustomDialog>
    </>
  );
}

function AccountInfo({ handleBack }: EntryPageProps) {
  const { imageUpload } = useImageUpload();
  const { putUserImage } = useUserHooks();
  const { refreshUser } = useContext(GlobalContext);
  const user = useUser();
  if (!user) return null;
  return (
    <>
      <Header label="Account info" handleBack={handleBack} />
      <div style={{ padding: 30 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ImageUpload
            handleChange={(file) =>
              imageUpload(file).then(({ location }) =>
                putUserImage(location).then(refreshUser)
              )
            }
          >
            <Avatar width={92} height={92} src={user.meta.image} />
          </ImageUpload>
        </div>
        <Horizontal marginTop={30} marginBottom={30} />
        <InfoComponent label="Name" value={user.meta.firstname} />
        <Horizontal marginTop={30} marginBottom={30} />
        <InfoComponent label="Email" value={user.meta.email} />
        <Horizontal marginTop={30} marginBottom={30} />
        <InfoComponent
          label="Instagram"
          value={user.meta.social_medias?.instagram ?? ""}
        />
        <Horizontal marginTop={30} marginBottom={30} />
      </div>
    </>
  );
}

function TermsAndCondition({ handleBack }: EntryPageProps) {
  return (
    <>
      <Header label="Terms & Conditions" handleBack={handleBack} />
      <div style={{ padding: 30 }}>
        <div style={{ fontWeight: 700 }}>Acceptance of Terms</div>
        <ul>
          <li>
            By using the Tably platform, you agree to comply with and be bound
            by the following terms and conditions. If you do not agree with
            these terms, please refrain from using our services.
          </li>
        </ul>
        <div style={{ fontWeight: 700, marginTop: 30 }}>
          User Responsibilities
        </div>
        <ul>
          <li>
            Users are responsible for providing accurate and up-to-date
            information during registration.
          </li>
          <li>
            Guests must behave respectfully at hosted dinner parties and adhere
            to house rules.
          </li>
        </ul>
        <div style={{ fontWeight: 700, marginTop: 30 }}>
          Dinner Party Bookings
        </div>
        <ul>
          <li>
            Guests can browse and sign up for dinner parties hosted by others.
          </li>
          <li>
            Hosts are responsible for providing accurate details of their
            events.
          </li>
        </ul>
        <div style={{ fontWeight: 700, marginTop: 30 }}>
          Cancellation Policy
        </div>
        <ul>
          <li>
            Guests may cancel their attendance up to 48 hours before the
            scheduled event without penalty.
          </li>
          <li>
            Cancellations made within 48 hours will result in a non-refundable
            fee equal to 50% of the total booking cost.
          </li>
          <li>No-shows will be charged the full booking amount.</li>
          <li>Exceptions for documented emergencies.</li>
        </ul>
        <div style={{ fontWeight: 700, marginTop: 30 }}>
          Host Responsibilities
        </div>
        <ul>
          <li>Hosts must honor their commitment to host confirmed events.</li>
          <li>
            Tably reserves the right to take appropriate actions, including
            temporary suspension, for frequent host cancellations.
          </li>
        </ul>

        <div style={{ fontWeight: 700, marginTop: 30 }}>
          Intellectual Property
        </div>
        <ul>
          <li>
            Tably retains ownership of the platform's intellectual property.
          </li>
          <li>
            Users must not use, reproduce, or distribute content from Shared
            Table without permission.
          </li>
        </ul>

        <div style={{ fontWeight: 700, marginTop: 30 }}>Privacy Policy</div>
        <ul>
          <li>
            Tably respects user privacy. Tably reserves the right to collect
            your data but not share your personal information.
          </li>
        </ul>

        <div style={{ fontWeight: 700, marginTop: 30 }}>
          Modification of Terms
        </div>
        <ul>
          <li>
            Tably reserves the right to modify these terms at any time. Users
            will be notified of changes.
          </li>
        </ul>

        <div style={{ fontWeight: 700, marginTop: 30 }}>
          Limitation of Liability
        </div>
        <ul>
          <li>
            Tably is not liable for damages arising from the use of the platform
            or participating in dinner parties.
          </li>
        </ul>

        <div style={{ marginTop: 30 }}>
          By using Tably, you agree to these terms and conditions. Please review
          them regularly for any updates.
        </div>
      </div>
    </>
  );
}

enum EntryPage {
  profile = "profile",
  account_info = "account_info",
  terms_and_condition = "terms_and_condition",
  event_create = "event_create",
}

interface ProfileEvent {
  event_id: number;
  event_code: number;
  image: string;
  title: string;
  host_name: string;
  host_image: string;
  hosting: boolean;
}

interface Profile {
  id: number;
  image: string;
  firstname: string;
  instagram_id: string;
  hosted: number;
  attended: number;
  upcomings: ProfileEvent[];
  pasts: ProfileEvent[];
  incompletes: ProfileEvent[];
  not_goings: ProfileEvent[];
}

interface UserProfileProps {
  setPage: (page: EntryPage) => void;
}

enum EventShowing {
  upcoming = "upcoming",
  past = "past",
  not_going = "not_going",
}

function UserProfile({ setPage }: UserProfileProps) {
  const { signout } = useUserHooks();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [showing, setShowing] = useState<EventShowing>(EventShowing.upcoming);

  useEffect(() => {
    fetchData<Profile>("GET", `/profile`).then(setProfile);
  }, []);

  if (!profile) return null;

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          flex: 1,
          backgroundColor: colors.ivory,
          color: colors.black,
          padding: 30,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar width={92} height={92} src={profile.image} />
          <div
            style={{
              marginLeft: 30,
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            <div>{profile.firstname}</div>
            <div
              style={{
                marginTop: 5,
                columnGap: 10,
                display: "flex",
                fill: colors.black,
                alignItems: "center",
              }}
            >
              {Boolean(profile.instagram_id) && (
                <InstagramSvg
                  style={{ height: 20, cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      `https://www.instagram.com/${profile.instagram_id}`,
                      "_blank"
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <div
            style={{
              backgroundColor: colors.grey,
              borderRadius: 20,
              padding: 10,
              color: colors.black,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontWeight: 600,
              fontSize: 25,
            }}
          >
            <div style={{ flex: 1, position: "relative" }}>
              {profile.hosted}
              <div
                style={{
                  position: "absolute",
                  top: 50,
                  fontSize: 12,
                  left: 0,
                  right: 0,
                }}
              >
                Meals Hosted
              </div>
            </div>
            <div
              style={{ width: 1, height: 32, backgroundColor: colors.black }}
            ></div>
            <div style={{ flex: 1, position: "relative" }}>
              {profile.attended}

              <div
                style={{
                  position: "absolute",
                  top: 50,
                  fontSize: 12,
                  left: 0,
                  right: 0,
                }}
              >
                Meals Attended
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              columnGap: 10,
              filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))",
              overflow: "auto",
              marginLeft: -30,
              marginRight: -30,
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <RoundButton
              color={colors.black}
              handleClick={() => setShowing(EventShowing.upcoming)}
              backgroundColor={
                showing === EventShowing.upcoming ? colors.blue : colors.ivory
              }
            >
              Upcoming
            </RoundButton>
            <RoundButton
              color={colors.black}
              handleClick={() => setShowing(EventShowing.past)}
              backgroundColor={
                showing === EventShowing.past ? colors.blue : colors.ivory
              }
            >
              Past
            </RoundButton>
            <RoundButton
              color={colors.black}
              handleClick={() => setShowing(EventShowing.not_going)}
              backgroundColor={
                showing === EventShowing.not_going ? colors.blue : colors.ivory
              }
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Not Going
            </RoundButton>
          </div>
          <div
            style={{
              marginTop: 30,
              overflow: "scroll",
              display: "flex",
              columnGap: 10,
              marginLeft: -30,
              marginRight: -30,
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            {showing === EventShowing.upcoming &&
              profile.upcomings.map((event, index) => (
                <EventCard event={event} key={index} />
              ))}
            {showing === EventShowing.past &&
              profile.pasts.map((event, index) => (
                <EventCard event={event} key={index} />
              ))}
            {showing === EventShowing.not_going &&
              profile.not_goings.map((event, index) => (
                <EventCard event={event} key={index} />
              ))}
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 30,
        }}
      >
        <RoundButton
          handleClick={() => setPage(EntryPage.event_create)}
          backgroundColor={colors.yellow}
          color={colors.black}
        >
          Create Event
        </RoundButton>
        <Horizontal marginTop={20} marginBottom={20} opacity={0.5} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setPage(EntryPage.account_info)}
        >
          <div>Account Info</div>
          <ChevronRightIcon />
        </div>
        <Horizontal marginTop={20} marginBottom={20} opacity={0.5} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setPage(EntryPage.terms_and_condition)}
        >
          <div>Terms & Conditions</div>
          <ChevronRightIcon />
        </div>
        <Horizontal marginTop={20} marginBottom={20} opacity={0.5} />
        <div onClick={signout}>Sign Out</div>
        <Horizontal marginTop={20} marginBottom={20} opacity={0.5} />
      </div>
    </div>
  );
}

function UserEntry() {
  const user = useUser();
  const [page, setPage] = useState<EntryPage>(EntryPage.profile);

  if (!user) return null;
  if (page === EntryPage.account_info)
    return <AccountInfo handleBack={() => setPage(EntryPage.profile)} />;
  if (page === EntryPage.terms_and_condition)
    return <TermsAndCondition handleBack={() => setPage(EntryPage.profile)} />;
  if (page === EntryPage.event_create)
    return <EventEdit handleBack={() => setPage(EntryPage.profile)} />;
  return <UserProfile setPage={setPage} />;
}

function TableInit() {
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={LogoIcon} alt="" style={{ height: 90 }} />
        <div style={{ marginTop: 15 }}>
          <SigninComponent />
        </div>
      </div>
    </>
  );
}

function Init() {
  const user = useUser();
  if (!user) return <TableInit />;
  return <UserEntry />;
}

export default Init;
