import React from "react";
import Horizontal from "../components/Horizontal";
import { colors } from "../hooks/color";

interface ScrollSelectItem {
  label: string;
  value: string;
}

interface ScrollSelectProps {
  label: string;
  items: ScrollSelectItem[];
  values: string[];
  handleChange: (values: string[]) => void;
}

function ScrollSelect({
  label,
  items,
  values,
  handleChange,
}: ScrollSelectProps) {
  return (
    <div>
      <div
        style={{
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        {label}
      </div>
      <Horizontal marginTop={15} />
      <div style={{ overflow: "auto", height: 200 }}>
        {items.map((item, index) => {
          const found = values.find((value) => value === item.value);
          return (
            <div
              style={{
                marginTop: 15,
                cursor: "pointer",
                color: colors.black,
                fontWeight: found ? 700 : 400,
              }}
              key={index}
              onClick={() => {
                // if on, then off
                // if off then on
                if (!found) {
                  values.push(item.value);
                  handleChange(values);
                } else {
                  handleChange(values.filter((value) => value !== item.value));
                }
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ScrollSelect;
