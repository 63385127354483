export enum PaymentMethod {
  venmo = "venmo",
  paypal = "paypal",
}

export enum HostStatus {
  pending = "pending",
  approved = "approved",
  blocked = "blocked",
}

export enum UserMode {
  unsigned = "unsigned",
  guest = "guest",
  host = "host",
  admin = "admin",
}

export enum EventDisclosure {
  public = "public",
  private = "private",
}

export enum CustomerEventStatus {
  live = "live",
  full = "full",
  closed = "closed",
  booked = "booked",
}

export enum EventDetailMode {
  stranger = "stranger",
  reserved = "reserved",
  hosting = "hosting",
  admin = "admin",
}

export enum EventStatus {
  under_review = "under_review",
  progress = "progress",
  cancelled = "cancelled",
  ready = "ready",
  rejected = "rejected",
  expired = "expired",
  published = "published",
  started = "started",
  closed = "closed",
}

export enum YesNo {
  yes = "yes",
  no = "no",
}

export enum EventItemType {
  all = "all",
  pending = "pending",
  upcoming = "upcoming",
  past = "past",
  cancelled = "cancelled",
  approved = "approved",
  rejected = "rejected",
  draft = "draft",
}

export enum SocketMessageType {
  message = "message",
  room = "room",
}

export enum MessageType {
  message = "message",
  joined = "joined",
  left = "left",
}

export enum UserNotificationType {
  host_rating = "host_rating",
}

export enum HostNotificationType {
  user_rating = "user_rating",
  event_ready = "event_ready",
  event_rejected = "event_rejected",
  event_expired = "event_expired",
}

export enum NotificationStatus {
  read = "read",
  unread = "unread",
}

export enum UserVerificationStatus {
  unverified = "unverified",
  email_verified = "email_verified",
  id_pending = "id_pending",
  full_verified = "full_verified",
}

export enum EventPaymentType {
  paid = "paid",
  free = "free",
}

export enum EventDetailSelfStatus {
  stranger = "stranger",
  going = "going",
  not_going = "not_going",
  pending = "pending",
  host = "host",
}
