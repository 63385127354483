import { Fragment, ReactNode, useState } from "react";
import { useUser, useUserHooks } from "../hooks/user";
import Horizontal from "../components/Horizontal";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { colors } from "../hooks/color";
import { useCurrentMode, useModeHooks } from "../hooks/mode";
import CustomSwitch from "../inputs/CustomSwitch";
import { useNavigate } from "react-router-dom";
import CustomImage from "../components/CustomImage";
import RoundButton from "../components/RoundButton";
import ProfileSignedoutPng from "../pngs/profile_signedout.png";
import ProfileHostPng from "../pngs/profile_host.png";
import { fontFamily } from "../hooks/font";
import UserProfile from "../components/UserProfile";
import { HostStatus, UserMode, UserVerificationStatus } from "../enums";
import { useHostedEventItems } from "../hooks/event";
import { fetchData } from "../api";
import VerificationWrapper from "../components/VerificationWrapper";
import { SigninComponent } from "./Sign";

interface UserModeSwitchComponentProps {
  color?: string;
  backgroundColor?: string;
}

function UserModeSwitchComponent({
  color = colors.ivory,
}: UserModeSwitchComponentProps) {
  const currentMode = useCurrentMode();
  const { patchUserMode } = useModeHooks();
  const navigate = useNavigate();
  const user = useUser();
  if (!user) return null;
  return (
    <div
      style={{
        color,
        display: "flex",
        flexDirection: "column",
        rowGap: 15,
      }}
    >
      {!user?.host && (
        <VerificationWrapper
          handleClick={() => navigate("/profile/become_a_host")}
        >
          <RoundButton backgroundColor={colors.orange} color={color}>
            Become a Host
          </RoundButton>
        </VerificationWrapper>
      )}
      {user.host && user.host.meta.status === HostStatus.approved && (
        <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
          <div>
            Switch to {currentMode !== UserMode.host ? "Host" : "Guest"}
          </div>
          <div>
            <CustomSwitch
              checked={currentMode === "host"}
              color={color}
              handleChange={(checked) => {
                if (!checked) {
                  patchUserMode(UserMode.guest);
                } else {
                  patchUserMode(UserMode.host);
                }
              }}
            />
          </div>
        </div>
      )}
      {user.admin && (
        <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
          <div>
            Switch to {currentMode !== UserMode.admin ? "Admin" : "Guest"}
          </div>
          <div>
            <CustomSwitch
              checked={currentMode === "admin"}
              color={color}
              handleChange={(checked) => {
                if (!checked) {
                  patchUserMode(UserMode.guest);
                } else {
                  patchUserMode(UserMode.admin);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const unsignedProfileMetas = [
  {
    label: "Terms & Condition",
    type: "terms_and_condition",
  },
  {
    label: "FAQ",
    type: "faq",
  },
] as ProfileMeta[];

const signedProfileMetas = [
  {
    label: "Account Info",
    type: "account_info",
  },
  {
    label: "Terms & Condition",
    type: "terms_and_condition",
  },
  {
    label: "User Guideline",
    type: "guideline",
  },
  {
    label: "FAQ",
    type: "faq",
  },
] as ProfileMeta[];

const hostProfileMetas = [
  {
    label: "About Me",
    type: "about_me",
  },
  {
    label: "Account Info",
    type: "account_info",
  },
  {
    label: "Payouts",
    type: "payouts",
  },
  // {
  //   label: "Promo & Rewards",
  //   type: "promo_rewards",
  // },
  {
    label: "Terms & Condition",
    type: "terms_and_condition",
  },
  {
    label: "FAQ",
    type: "faq",
  },
] as ProfileMeta[];

interface MenuComponentProps {
  label: ReactNode;
  handleClick?: () => void;
  noRight?: boolean;
}

function MenuComponent({ label, handleClick, noRight }: MenuComponentProps) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        fontWeight: 600,
      }}
      onClick={handleClick}
    >
      <div>{label}</div>
      {!noRight && <ChevronRightIcon />}
    </div>
  );
}

function Unsigned() {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ flex: 1, position: "relative" }}>
        <CustomImage
          src={ProfileSignedoutPng}
          style={{ height: "100%", backgroundPosition: "top" }}
        />
        <div
          style={{
            position: "absolute",
            top: "30%",
            fontSize: 28,
            fontFamily: fontFamily.simula,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Welcome to Tably
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 90,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {/* <RoundButton
            backgroundColor={colors.green}
            color={colors.yellow}
            style={{ padding: "10px 20px" }}
            handleClick={() =>
              navigate("/sign", {
                state: {
                  redirect: "/profile",
                },
              })
            }
          >
            Sign In
          </RoundButton> */}
          <SigninComponent />
        </div>
      </div>
      <div
        style={{
          padding: 30,
          backgroundColor: colors.green,
          color: colors.ivory,
        }}
      >
        <Horizontal
          horizontalColor={colors.ivory}
          marginTop={20}
          marginBottom={20}
          opacity={0.5}
        />
        {unsignedProfileMetas.map((profileMeta, index) => (
          <Fragment key={index}>
            <MenuComponent
              label={profileMeta.label}
              handleClick={() => navigate(`/profile/${profileMeta.type}`)}
            />
            <Horizontal
              horizontalColor={colors.ivory}
              marginTop={20}
              marginBottom={20}
              opacity={0.5}
            />
          </Fragment>
        ))}
        <VerificationWrapper
          handleClick={() => navigate("/profile/become_a_host")}
        >
          <RoundButton backgroundColor={colors.orange} color={colors.ivory}>
            Become a Host
          </RoundButton>
        </VerificationWrapper>
      </div>
    </>
  );
}

function Signed() {
  const navigate = useNavigate();
  const { signout } = useUserHooks();
  const user = useUser();
  const userMode = useCurrentMode();
  const [loaded, setLoaded] = useState<boolean>(false);
  if (!user) return null;
  return (
    <>
      <div
        style={{
          padding: 30,
        }}
      >
        <UserProfile
          userId={user.id}
          visualize={{
            avatar: true,
            summary: true,
            attendedEvents: true,
            userReviews: true,
          }}
          avatarEditable
          userMode={userMode}
          afterLoadCallback={() => {
            setLoaded(true);
          }}
        />
      </div>
      <div
        style={{
          marginTop: -30,
          color:
            user.meta.verification_status === UserVerificationStatus.unverified
              ? colors.white
              : colors.green,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div>
            <div
              style={{
                width: 450,
                height: 300,
                borderTopLeftRadius: "50%",
                borderTopRightRadius: "50%",
                backgroundColor:
                  user.meta.verification_status ===
                  UserVerificationStatus.unverified
                    ? colors.green
                    : colors.yellow,
              }}
            ></div>
          </div>
          <div
            style={{
              position: "absolute",
              top: 50,
              width: 250,
              textAlign: "center",
            }}
          >
            <div style={{ fontFamily: fontFamily.simula, fontSize: 30 }}>
              Hi {user.meta.firstname}.
              <br />
              Welcome to Tably.
              {user.meta.verification_status ===
                UserVerificationStatus.full_verified && (
                <>
                  <br />
                  You’re all set!
                </>
              )}
              {user.meta.verification_status ===
                UserVerificationStatus.id_pending && (
                <>
                  <br />
                  We are verifying your identity at the moment.
                </>
              )}
            </div>
            <div style={{ marginTop: 30, fontSize: 14 }}>
              {user.meta.verification_status ===
                UserVerificationStatus.unverified &&
                "Please check your inbox and confirm your email."}
              {user.meta.verification_status ===
                UserVerificationStatus.email_verified &&
                "Finish setting up your account by adding a photo of your government ID."}
              {user.meta.verification_status ===
                UserVerificationStatus.full_verified &&
                "See what’s on the table."}
            </div>
            {user.meta.verification_status ===
              UserVerificationStatus.unverified && (
              <div
                style={{
                  marginTop: 30,
                  fontSize: 14,
                  color: colors.orange,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => fetchData("POST", `/users/confirmation/resend`)}
              >
                Resend Email
              </div>
            )}
            {user.meta.verification_status ===
              UserVerificationStatus.email_verified && (
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <VerificationWrapper>
                  <RoundButton style={{ width: 100 }}>Upload</RoundButton>
                </VerificationWrapper>
              </div>
            )}
            {user.meta.verification_status ===
              UserVerificationStatus.full_verified && (
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <RoundButton handleClick={() => navigate("/event")}>
                  Explore Events
                </RoundButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {user.meta.verification_status === UserVerificationStatus.unverified && (
        <div>
          <Horizontal horizontalColor={colors.white} />
        </div>
      )}
      {loaded && (
        <div
          style={{
            padding: 30,
            backgroundColor: colors.green,
            color: colors.ivory,
            flex: 1,
          }}
        >
          <Horizontal
            horizontalColor={colors.ivory}
            marginTop={20}
            marginBottom={20}
            opacity={0.5}
          />
          {signedProfileMetas.map((profileMeta, index) => (
            <Fragment key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/profile/${profileMeta.type}`)}
              >
                <div>{profileMeta.label}</div>
                <div style={{ cursor: "pointer" }}>
                  <ChevronRightIcon />
                </div>
              </div>
              <Horizontal
                horizontalColor={colors.ivory}
                marginTop={20}
                marginBottom={20}
                opacity={0.5}
              />
            </Fragment>
          ))}
          <div
            style={{
              color: colors.orange,
              fontSize: 14,
              fontWeight: 700,
              cursor: "pointer",
            }}
            onClick={signout}
          >
            Log Out
          </div>
          <Horizontal
            horizontalColor={colors.ivory}
            marginTop={20}
            marginBottom={20}
            opacity={0.5}
          />
          <div>
            <UserModeSwitchComponent />
          </div>
        </div>
      )}
    </>
  );
}

function Host() {
  const navigate = useNavigate();
  const { signout } = useUserHooks();
  const user = useUser();
  const userMode = useCurrentMode();
  const { eventItems } = useHostedEventItems(user?.host?.id ?? 0);
  const [loaded, setLoaded] = useState<boolean>(false);
  if (!user || !user.host || !eventItems) return null;
  return (
    <>
      <div
        style={{
          padding: 30,
          position: "relative",
        }}
      >
        {eventItems.length === 0 && (
          <>
            <div style={{ position: "absolute", inset: 0 }}>
              <CustomImage
                src={ProfileHostPng}
                style={{ height: "100%", backgroundPosition: "top" }}
              />
            </div>
            <div style={{ zIndex: 1, position: "relative" }}>
              <UserProfile
                userId={user.id}
                visualize={{
                  avatar: true,
                }}
                avatarEditable
                userMode={userMode}
                afterLoadCallback={() => {
                  setLoaded(true);
                }}
              />
              <div
                style={{
                  marginTop: 100,
                  color: colors.orange,
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    fontFamily: fontFamily.simula,
                    fontSize: 40,
                  }}
                >
                  Congratulations {user.meta.firstname}.
                </div>
                <div
                  style={{
                    marginTop: 50,
                    fontWeight: 500,
                    fontSize: 30,
                  }}
                >
                  You’re now a host!
                </div>
              </div>
            </div>
          </>
        )}
        {eventItems.length > 0 && (
          <UserProfile
            userId={user.id}
            visualize={{
              avatar: true,
              hostSummary: true,
              aboutMe: true,
              hostedEvents: true,
              hostReviews: true,
            }}
            userMode={userMode}
            afterLoadCallback={() => {
              setLoaded(true);
            }}
          />
        )}
      </div>
      {loaded && (
        <div
          style={{
            padding: 30,
            backgroundColor: colors.orange,
          }}
        >
          <div>
            <RoundButton
              backgroundColor={colors.yellow}
              color={colors.orange}
              handleClick={() => navigate("/events/new/edit")}
            >
              Create New Event
            </RoundButton>
          </div>
          <Horizontal
            horizontalColor={colors.ivory}
            marginTop={20}
            marginBottom={20}
            opacity={0.5}
          />
          {hostProfileMetas.map((profileMeta, index) => (
            <Fragment key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  color: colors.ivory,
                }}
                onClick={() => navigate(`/profile/${profileMeta.type}`)}
              >
                <div>{profileMeta.label}</div>
                <div style={{ cursor: "pointer" }}>
                  <ChevronRightIcon />
                </div>
              </div>
              <Horizontal
                horizontalColor={colors.ivory}
                marginTop={20}
                marginBottom={20}
                opacity={0.5}
              />
            </Fragment>
          ))}
          <div
            style={{
              color: colors.yellow,
              fontSize: 14,
              fontWeight: 700,
              cursor: "pointer",
            }}
            onClick={signout}
          >
            Log Out
          </div>
          <Horizontal
            horizontalColor={colors.ivory}
            marginTop={20}
            marginBottom={20}
            opacity={0.5}
          />
          <UserModeSwitchComponent />
        </div>
      )}
    </>
  );
}

function Admin() {
  const navigate = useNavigate();
  const user = useUser();
  const userMode = useCurrentMode();
  const { signout } = useUserHooks();
  if (!user) return null;
  return (
    <>
      <div style={{ padding: 30 }}>
        <UserProfile
          userId={user.id}
          visualize={{ avatar: true }}
          avatarEditable
          userMode={userMode}
        />
        <div style={{ marginTop: 30 }}>
          <UserModeSwitchComponent color={colors.green} />
        </div>
        <Horizontal marginTop={30} marginBottom={30} />
        <div>
          <MenuComponent
            label="Events"
            handleClick={() => navigate("/admin/events")}
          />
        </div>
        {/* <Horizontal marginTop={30} marginBottom={30} />
        <div>
          <MenuComponent label="Refunds" />
        </div> */}
        <Horizontal marginTop={30} marginBottom={30} />
        <div>
          <MenuComponent
            label="Hosts"
            handleClick={() => navigate("/admin/hosts")}
          />
        </div>
        <Horizontal marginTop={30} marginBottom={30} />
        <div>
          <MenuComponent
            label="Guests"
            handleClick={() => navigate("/admin/guests")}
          />
        </div>
        {/* <Horizontal marginTop={30} marginBottom={30} />
        <div>
          <MenuComponent label="Manage Promo & Rewards" />
        </div> */}
        {/* <Horizontal marginTop={30} marginBottom={30} />
        <div>
          <MenuComponent label="Analytics" />
        </div> */}
        <Horizontal marginTop={30} marginBottom={30} />
        <div>
          <MenuComponent
            label="FAQ"
            handleClick={() => navigate("/profile/faq")}
          />
        </div>
        <Horizontal marginTop={30} marginBottom={30} />
        <div>
          <MenuComponent label="Log Out" noRight handleClick={signout} />
        </div>
      </div>
    </>
  );
}

interface ProfileMeta {
  label: ReactNode;
  type: string;
  render: boolean;
}

function Profile() {
  const user = useUser();
  const currentMode = useCurrentMode();
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {(() => {
        if (!user) return <Unsigned />;
        if (currentMode === UserMode.host) return <Host />;
        if (currentMode === UserMode.admin) return <Admin />;
        return <Signed />;
      })()}
    </div>
  );
}

export default Profile;
