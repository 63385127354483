import { colors, useColor } from "../hooks/color";

interface HorizontalProps {
  mode?: boolean;
  marginTop?: number;
  marginBottom?: number;
  thick?: boolean;
  horizontalColor?: string;
  opacity?: number;
}

function Horizontal({
  mode,
  marginTop,
  marginBottom,
  thick,
  horizontalColor = colors.ivory,
  opacity,
}: HorizontalProps) {
  const color = useColor();
  return (
    <div
      style={{
        height: thick ? 2 : 1,
        backgroundColor: mode ? color : horizontalColor,
        marginTop,
        marginBottom,
        opacity,
      }}
    ></div>
  );
}

export default Horizontal;
