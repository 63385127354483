import React from "react";
import CustomImage from "./CustomImage";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { colors } from "../hooks/color";

interface AvatarNameProps {
  src?: string;
  width: number;
  pops?: number;
  label: string;
}

export function AvatarName({ src, width, pops, label }: AvatarNameProps) {
  return (
    <div
      style={{
        display: "inline-flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar src={src} width={width} height={width} />
      {pops && pops - 1 > 0 && (
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            backgroundColor: colors.yellow,
            color: colors.black,
            borderRadius: "50%",
            transform: "translate(50%,-50%)",
            fontSize: 12,
            width: 25,
            height: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          +{pops - 1}
        </div>
      )}
      <div
        style={{
          marginTop: 5,
          fontSize: 12,
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        {label}
      </div>
    </div>
  );
}

interface AvatarProps {
  src?: string;
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties;
}

function Avatar({ src, width, height, style }: AvatarProps) {
  return (
    <div
      style={{ position: "relative", display: "inline-block", width, height }}
    >
      <CustomImage
        src={src}
        width="100%"
        height="100%"
        style={{
          borderRadius: "50%",
          ...style,
        }}
      />
      {!src && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
          }}
        >
          <InsertPhotoIcon />
        </div>
      )}
    </div>
  );
}

export default Avatar;
